import { _post } from "./DefaultAction";


export const changePassword = ( payload, callback) => {
  _post(`/web/user/changepassword`, payload, callback, true);
};

export const forgotPassword = ( payload, callback) => {
  _post(`/web/user/forgotPassword`, payload, callback);
};

export const resetPassword = ( payload, callback) => {
  _post(`/web/user/completeReset`, payload, callback);
};