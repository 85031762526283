import axios from "axios";
import { _sortError, clientId, baseUrl } from "../utils";

const getToken = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("Authorization token is missing");
  }
  return token;
};

const getHeaders = (needsAuth = false) => ({
  "client-id": clientId,
  ...(needsAuth && { Authorization: `Bearer ${getToken()}` }),
});

const _axios = axios.create({
  baseURL: baseUrl,
});

export const _post = (url, payload, callback, needsAuth = false) => {
  _axios
    .post(url, payload, { headers: getHeaders(needsAuth) })
    .then((res) => {
      if (typeof callback === "function") {
        callback(true, res.data.data, res.status);
      }
    })
    .catch((err) => {
      callback(
        false,
        _sortError(err),
        err.response ? err.response.status : 400
      );
    });
};

export const _put = (url, payload, callback, needsAuth = false) => {
  _axios
    .put(url, payload, { headers: getHeaders(needsAuth) })
    .then((res) => {
      if (typeof callback === "function") {
        callback(true, res.data.data, res.status);
      }
    })
    .catch((err) => {
      callback(
        false,
        _sortError(err),
        err.response ? err.response.status : 400
      );
    });
};

export const _get = (url, params, callback, isPaginated, needsAuth = false) => {
  _axios
    .get(url, {
      params,
      headers: getHeaders(needsAuth),
    })
    .then((res) => {
      if (typeof callback === "function") {
        callback(true, isPaginated ? res.data : res.data.data);
      }
    })
    .catch((err) => {
      callback(false, _sortError(err));
    });
};

export const _delete = (url, params, callback, needsAuth = false) => {
  _axios
    .delete(url, {
      params,
      headers: getHeaders(needsAuth),
    })
    .then((res) => {
      if (typeof callback === "function") {
        callback(true, res.data.data);
      }
    })
    .catch((err) => {
      callback(false, _sortError(err));
    });
};




// import { _sortError, clientId, baseUrl } from "../utils";
// import axios from "axios";

// const token = localStorage.getItem("token");
// const _axios = axios.create({
//   baseURL: baseUrl,
//   headers: {
//     "client-id": clientId,
//   },
// });
// export const _post = (url, payload, callback, needsAuth = false) => {
//   const config = {
//     headers: needsAuth ? { Authorization: Bearer ${token} } : {},
//   };

//   _axios
//     .post(url, payload, config)
//     .then((res) => {
//       callback(true, res.data.data, res.status);
//     })
//     .catch((err) => {
//       callback(
//         false,
//         _sortError(err),
//         err.response ? err.response.status : 400
//       );
//     });
// };
// export const _put = (url, payload, callback) => {
//   _axios
//     .put(url, payload)
//     .then((res) => {
//       callback(true, res.data.data, res.status);
//     })
//     .catch((err) => {
//       callback(
//         false,
//         _sortError(err),
//         err.response ? err.response.status : 400
//       );
//     });
// };
// export const _get = (url, params, callback, isPaginated, needsAuth = false) => {
//   console.log({ needsAuth, token });
//   _axios
//     .get(url, {
//       params,
//       headers: { Authorization: "Bearer " + token},
//     })
//     .then((res) => {
//       callback(true, isPaginated ? res.data : res.data.data);
//     })
//     .catch((err) => {
//       if (typeof callback != "function") console.log("Callback", callback);
//       else callback(false, _sortError(err));
//     });
// };

// export const _delete = (url, params, callback, token) => {
//   _axios
//     .delete(url, {
//       params,
//     })
//     .then((res) => {
//       callback(true, res.data.data);
//     })
//     .catch((err) => {
//       callback(false, _sortError(err));
//     });
// };
