import { local } from "./storage";

export const baseUrl = process.env.REACT_APP_API_BASE_URL || "https://pmmb.tm30.net";
export const clientId = process.env.REACT_APP_CLIENT_ID;

export const _setToken = (token) => {
  return local.set("token", token);
};

export const _sortError = (e) => {
  console.log("Er", e);
  if (e.response === undefined) {
    return "No Internet Connection";
  } else {
    return e.response.data.error || "AN Error Occurred";
  }
};

export function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
  
  // return "#023054";
}

export function addKeysToObj(params) {
  let arr = [];
  params && params.map((data, index) => arr.push({ key: index, ...data }));
  return arr;
}
