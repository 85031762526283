import React, { useState } from "react";
// import "../shared/style/login.css";
import "../../../shared/style/login.css";
import { Eye } from "../customIcon";
import { EyeSlash } from "../customIcon/Eye";

const PasswordIcon = ({ handleChange, value, name, label }) => {
  const [toggle, setToggle] = React.useState(false);
  return (
    <div className="input-control">
      <label htmlFor={name} className="input-label" hidden>
        {label}
      </label>
      <input
        type={toggle ? 'text' : 'password'}
        name={name}
        className="input-field"
        placeholder={label}
        onChange={handleChange}
        value={value}
        required
      />

      {toggle ? (
        <i
          onClick={() => setToggle(!toggle)}
          style={{ opacity: 1 }}
          className={""}
        ><Eye/></i>
      ) : (
        <i
          onClick={() => setToggle(!toggle)}
          style={{ opacity: 1 }}
          // className={"fas fa-eye-slash"}
        ><EyeSlash/></i>
      )}
    </div>
  );
};

export default PasswordIcon;
