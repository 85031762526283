import React from "react";
import "../shared/style/login.css";
import axios from "axios";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import { useAuth } from "../shared/HOC/authContext";
import { forgotPassword } from "../actions/PasswordAction";
const ForgotPassword = () => {
  const [formData, setFormData] = React.useState({
    email: ""
  });
  const [loading, setLoading] = React.useState(false);
 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    forgotPassword(formData, (status, payload) => {
          setLoading(false);
          // Check if payload is an object and convert it to string if needed
          const messageText = typeof payload === 'object' ? 
            JSON.stringify(payload) : payload;
          
          if (status) {
            message.success( 'Please check your email for a password reset link.');
          } else {
            message.error(messageText || 'Please input registered email');
          }
        });
   
    
  };
  return (
    <main class="main">
      <div class="container">
        <section class="wrapper">
          <div class="heading">
            <h1 class="text text-large" style={{ textAlign: "center" }}>
             Forgot Password
            <p class="text-p">Please Input your registered email</p>

            </h1>
          
          </div>
          <form onSubmit={handleSubmit} class="form">
            <div class="input-control">
              <label for="email" class="input-label" hidden>
                Email Address
              </label>
              <input
                type="email"
                name="email"
                class="input-field"
                placeholder="Enter Email Address"
                onChange={handleChange}
                required
              />
            </div>
           
            <div class="input-control">
              <button class="input-submit" disabled={loading}>
                {loading ? "loading..." : "Recover Password"}
              </button>
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default ForgotPassword;
