import { message } from "antd";
import React from "react";
import "antd/dist/antd.css";
import "./index.css";
import { changePassword } from "../../actions/PasswordAction";
import PasswordIcon from "../common/inputIcon/PasswordIcon";

const Profile = () => {
  const userId = localStorage.getItem("userId");
  const [formData, setFormData] = React.useState({
    userId: userId,
    oldPassword: "",
    password: "",
    cpassword: "",
  });
  const [loading, setLoading] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if passwords match
    if (formData.password !== formData.cpassword) {
      message.error("New password and confirm password do not match");
      return;
    }

    setLoading(true);
    const { cpassword, ...data } = formData; // Exclude cpassword from the payload
    changePassword(data, (status, payload) => {
      setLoading(false);
      const messageText =
        typeof payload === "object" ? JSON.stringify(payload) : payload;

      if (status) {
        message.success("Password changed successfully");
        setFormData({
          userId: userId,
          oldPassword: "",
          password: "",
          cpassword: "",
        });
      } else {
        message.error(messageText || "Failed to change password");
      }
    });
  };

  return (
    <div>
      <h3 style={{ fontSize: "24px" }}>Change Password</h3>
      <form onSubmit={handleSubmit} className="form">

        <PasswordIcon
          handleChange={handleChange}
          value={formData.oldPassword}
          name={"oldPassword"}
          label={"Current Password"}
        />

        <PasswordIcon
          handleChange={handleChange}
          value={formData.password}
          name={"password"}
          label={"New Password"}
        />

        <PasswordIcon
          handleChange={handleChange}
          value={formData.cpassword}
          name={"cpassword"}
          label={ "Confirm Password"}
        />
       
        
        <div className="input-control">
          <button className="input-submit" disabled={loading}>
            {loading ? "Loading..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Profile;
