import React, { useEffect, useState } from "react";
import { Group, Level, Credit, Bank } from "../../components/common/customIcon";
import { Link, useHistory } from "react-router-dom";
import { primaryColor } from "../utils/data";
// import {Badge} from "antd";
// import {CommentOutlined, LineChartOutlined, SoundOutlined, UserOutlined} from "@ant-design/icons";
import ArrowDownOutlined from "@ant-design/icons/lib/icons/ArrowDownOutlined";
import { Profile } from "../../components/common/customIcon/Profile";

function SideBar(props) {
  const [showIndictaor, setShowIndictaor] = useState(false);
  const history = useHistory();
  let ulContainer = null;
  const [currentUser, setCurrentUser] = useState("");

  useEffect(() => {
    if (ulContainer) {
      setShowIndictaor(ulContainer.clientHeight < ulContainer.scrollHeight);
    }
  }, [ulContainer]);

  const logout = () => {
    localStorage.removeItem("clientId");
    localStorage.removeItem("role");
    history.push("/login");
  };

  return (
    <div className="side-bar">
      {/*<div className="brand"><img src="/logo.png" height="120" width="80"/></div>*/}
      <ul ref={(ref) => (ulContainer = ref)}>
        <Link
          to={"/admin/payments"}
          className={props.activeSideBar === "payments" ? "active" : ""}
        >
          <div className="side-icon">
            <Credit size={"20px"} />
          </div>
          Payments
        </Link>
        <Link
          to={"/admin/bills"}
          className={props.activeSideBar === "bills" ? "active" : ""}
        >
          <div className="side-icon">
            <Bank size={"20px"} />
          </div>
          Bills Vending
        </Link>
        <Link
          to={"/admin/users"}
          className={props.activeSideBar === "users" ? "active" : ""}
        >
          <div className="side-icon">
            <Group size={"20px"} />
          </div>
          Users
        </Link>
        <Link
          to={"/admin/services"}
          className={props.activeSideBar === "services" ? "active" : ""}
        >
          <div className="side-icon">
            <Level size={"20px"} />
          </div>
          Services
        </Link>
        <Link
          to={"/admin/providers"}
          className={props.activeSideBar === "providers" ? "active" : ""}
        >
          <div className="side-icon">
            <Level size={"20px"} />
          </div>
          Providers
        </Link>
        <Link
          to={"/admin/walletlogs"}
          className={props.activeSideBar === "walletlogs" ? "active" : ""}
        >
          <div className="side-icon">
            <Level size={"20px"} />
          </div>
          Wallet Logs
        </Link>

        <Link
          to={"/admin/profile"}
          className={props.activeSideBar === "profile" ? "active" : ""}
        >
          <div className="side-icon">
            <Profile size={"25px"} />
          </div>
          Profile
        </Link>

        <div className="side-icon logout" onClick={logout}>
          Logout
        </div>

        {/* <Link
         to={"/updateService"}
         className={props.activeSideBar === "updateService" ? "active" : ""}
         >
           <div className="side-icon">
           <Update size={"20px"} />
           </div>
           Update Service
         </Link> */}

        {/*<Link*/}
        {/*    to={"/admin/subscribers"}*/}
        {/*    className={props.activeSideBar === "subscribers" ? "active" : ""}*/}
        {/*>*/}
        {/*  <div className="side-icon">*/}
        {/*    <UserOutlined  style={{fontSize: 30}}/>*/}
        {/*  </div>*/}
        {/*  Subscribers*/}
        {/*</Link>*/}
        {/*<Link*/}
        {/*    to={"/admin/services"}*/}
        {/*    className={props.activeSideBar === "service" ? "active" : ""}*/}
        {/*>*/}
        {/*  <div className="side-icon">*/}
        {/*    {*/}
        {/*      props.unread && props.unread.products ?*/}
        {/*          <Badge count={props.unread.products}>*/}
        {/*            <File size={"20px"} />*/}
        {/*          </Badge> :*/}
        {/*          <File size={"20px"} />*/}
        {/*    }*/}
        {/*  </div>*/}
        {/*  Products*/}
        {/*</Link>*/}
        {/*<Link*/}
        {/*    to={"/admin/campaign"}*/}
        {/*    className={props.activeSideBar === "campaign" ? "active" : ""}*/}
        {/*>*/}
        {/*  <div className="side-icon">*/}
        {/*    <SoundOutlined  style={{fontSize: 30}}/>*/}
        {/*  </div>*/}
        {/*  Campaign*/}
        {/*</Link>*/}

        {/*<Link*/}
        {/*    to={"/admin/finance"}*/}
        {/*    className={props.activeSideBar === "finance" ? "active" : ""}*/}
        {/*>*/}
        {/*  <div className="side-icon">*/}
        {/*    <LineChartOutlined  style={{fontSize: 30}}/>*/}
        {/*  </div>*/}
        {/*  Finance*/}
        {/*</Link>*/}
        {/*<Link*/}
        {/*    to={"/admin/support"}*/}
        {/*    className={props.activeSideBar === "support" ? "active" : ""}*/}
        {/*>*/}
        {/*  <div className="side-icon">*/}
        {/*    {*/}
        {/*      props.unread && props.unread.tickets ?*/}
        {/*          <Badge count={props.unread.tickets}>*/}
        {/*            <CommentOutlined  style={{fontSize: 30}}/>*/}
        {/*          </Badge> :*/}
        {/*          <CommentOutlined  style={{fontSize: 30}}/>*/}
        {/*    }*/}
        {/*  </div>*/}

        {/*  Support*/}
        {/*</Link>*/}
        {/*<Link*/}
        {/*    to={"/admin/settings"}*/}
        {/*    className={props.activeSideBar === "settings" ? "active" : ""}*/}
        {/*>*/}
        {/*  <div className="side-icon">*/}
        {/*    <Gear size={"20px"} />*/}
        {/*  </div>*/}
        {/*  Settings*/}
        {/*</Link>*/}

        {/*<Link*/}
        {/*    to={`/admin/account/${userData.userId || userData.data.userId}`}*/}
        {/*    className={props.activeSideBar === "account" ? "active" : ""}*/}
        {/*>*/}
        {/*  <div className="side-icon">*/}
        {/*    <Gear size={"20px"} />*/}
        {/*  </div>*/}
        {/*  Profile*/}
        {/*</Link>*/}
      </ul>

      {showIndictaor && (
        <div className="down-indicator">
          {/*<Icon name="chevronDown" type="feather" size={20} />*/}
          <ArrowDownOutlined style={{ color: primaryColor, fontSize: 20 }} />
        </div>
      )}
    </div>
  );
}

export default SideBar;
