import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Spinner } from "../components/common/spinner";
import AdminLayout from "./layouts/AdminLayout/AdminLayout";
import { primaryColor } from "./utils/data";
import Login from "../components/Login";
import PrivateRoute from "../components/PrivateRoute";
// import AuthController from "./HOC/authcontroller";
import AuthProvider from "./HOC/authContext";
import ForgotPassword from "../components/ForgotPassword";
import ResetPassword from "../components/ResetPassword";

export const Loading = (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "20vh",
    }}
  >
    <Spinner size={30} color={primaryColor} />
  </div>
);

// const ForgotPassword = lazy(() =>
//     import("./AdminComponents/forgotPassword/forgotPassword")
// );
// const ResetPassword = lazy(() =>
//     import("./AdminComponents/forgotPassword/resetPassword")
// );
// const ViewProduct = lazy(() =>
//     import("./AdminComponents/products/productView")
// );
// const Campaign = lazy(() => import("./AdminComponents/campaign/campaign"));
// const SingleCampaign = lazy(() =>
//     import("./AdminComponents/campaign/components/singleCampaign")
// );

const Routes = () => (
  <BrowserRouter>
    <AuthProvider>
      <Switch>
        <Redirect path="/admin" exact to="/admin/payments" />
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <PrivateRoute path="/admin" component={AdminLayout} />
        <Route path="/login" exact component={Login} />
        <Route path="/password/forgot" exact component={ForgotPassword} />
        <Route path="/password/reset" exact component={ResetPassword} />

        <Route path="/404" component={() => <div>404 page not found!</div>} />
        <Redirect path="*" exact to="/404" />
      </Switch>
    </AuthProvider>
  </BrowserRouter>
);

export default Routes;
