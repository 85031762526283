import React from "react";
import "../shared/style/login.css";
import { message } from "antd";
import { useLocation } from "react-router-dom";
import { resetPassword } from "../actions/PasswordAction";
import PasswordIcon from "./common/inputIcon/PasswordIcon";

const ResetPassword = () => {
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
    cpassword: "",
  });
  const [loading, setLoading] = React.useState(false);

  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!token) {
      message.error("Invalid or missing reset token.");
      return;
    } else if (formData.password !== formData.cpassword) {
      message.error("New password and confirm password do not match");
      return;
    }

    setLoading(true);
    const { cpassword, ...data } = formData; // Exclude cpassword from the payload

    resetPassword({ ...data, token }, (status, payload) => {
      setLoading(false);
      const messageText =
        typeof payload === "object" ? JSON.stringify(payload) : payload;

      if (status) {
        message.success("Your password has been changed successfully");

        window.location.href = "/login";  // Redirects to the login page

      } else {
        message.error(messageText || "Please input your new password.");
      }
    });
  };

  return (
    <main className="main">
      <div className="container">
        <section className="wrapper">
          <div className="heading">
            <h1 className="text text-large" style={{ textAlign: "center" }}>
              Reset Password
            </h1>
            <p className="text-p">Please input your new password</p>
          </div>
          <form onSubmit={handleSubmit} className="form">
            <div className="input-control">
              <label htmlFor="email" className="input-label" hidden>
                Email Address
              </label>
              <input
                type="email"
                name="email"
                className="input-field"
                placeholder="Email Address"
                onChange={handleChange}
                value={formData.email}
                required
              />
            </div>
            {/* <div className="input-control">
              <label htmlFor="password" className="input-label" hidden>
                Password
              </label>
              <input
                type="password"
                name="password"
                className="input-field"
                placeholder="New Password"
                onChange={handleChange}
                value={formData.password}
                required
              />
            </div> */}

            <PasswordIcon
              handleChange={handleChange}
              value={formData.password}
              name={"password"}
              label={"Password"}
            />

            <PasswordIcon
              handleChange={handleChange}
              value={formData.cpassword}
              name={"cpassword"}
              label={"Confirm Password"}
            />


            <div style={{ marginBottom: "20px", textAlign: "center" }}>
              <a href={"./password/forgot"} style={{ textDecoration: "none" }}>
                Token Expired? <b>Resend link here.</b>
              </a>
            </div>

            <div className="input-control" style={{ width: "100%" }}>
              <button
                className="input-submit"
                disabled={loading}
                style={{ width: "100%" }}
              >
                {loading ? "Loading..." : "Recover Password"}
              </button>
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default ResetPassword;
